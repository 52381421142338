<template>
  <div>
    <section v-if="selectedOption" class="section">
      <div class="container">
        <div>{{ selectedOption.Amount }} x {{ selectedOption.OptionName }}</div>

        <div class="columns">
          <div class="field column is-narrow">
            <div class="control has-icons-left">
              <div class="select">
                <select
                  v-if="reservationSpaces.length > 0"
                  class="input"
                  v-model="selectedOption.SpaceId"
                >
                  <option :selected="selectedOption.SpaceId === 0" :value="0">{{
                    $t(
                      'Components.Reservation.AddReservationOptions.Select_OptionSpaceNonApplicable'
                    )
                  }}</option>
                  <optgroup label="--Booked space(s)--">
                    <option
                      v-for="(reservationSpace, index) in reservationSpaces"
                      :key="index"
                      :selected="
                        selectedOption.SpaceId === reservationSpace.SpaceId
                      "
                      :value="reservationSpace.SpaceId"
                      >{{ reservationSpace.SpaceName }}</option
                    >
                  </optgroup>
                  <optgroup v-if="spaces.length > 0" label="--Other space(s)--">
                    <option
                      v-for="(space, index) in spaces"
                      :key="index"
                      :selected="option.SpaceId === space.Id"
                      :value="space.Id"
                      >{{ space.Name }}</option
                    >
                  </optgroup>
                </select>
              </div>
              <div class="icon is-small is-left">
                <font-awesome-icon :icon="['fas', 'linkedin-in']" />
              </div>
            </div>
          </div>

          <div
            v-if="selectedOption.TimeSelectable > 0"
            class="field column is-narrow"
          >
            <div class="control has-icons-left">
              <div class="select">
                <select v-model="selectedOption.SelectedTime">
                  <option
                    :value="-1"
                    :selected="selectedOption.SelectedTime === -1"
                    >{{
                      $t(
                        'Components.Reservation.AddReservationOptions.Select_OptionTimeNonApplicable'
                      )
                    }}</option
                  >
                  <option
                    v-for="timeSlot in timeSlots"
                    :key="timeSlot"
                    :value="timeSlot"
                    :selected="selectedOption.SelectedTime === timeSlot"
                    >{{ timeSlot | minutesToTime }}</option
                  >
                </select>
              </div>
              <div class="icon is-small is-left">
                <font-awesome-icon :icon="['fas', 'at']" />
              </div>
            </div>
          </div>
        </div>

        <div>
          <a @click="cancelSelectedOption">{{
            $t('Components.Reservation.AddReservationOptions.Button_Cancel')
          }}</a>
          <a
            @click="addOptionToReservation"
            class="has-margin-left button is-primary is-small"
          >
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'plus']" />
            </span>
            <span>{{
              $t('Components.Reservation.AddReservationOptions.Button_Add')
            }}</span>
          </a>
        </div>
      </div>
    </section>

    <div v-if="!selectedOption">
      <section class="section">
        <div class="container">
          <div class="field has-addons">
            <div class="control">
              <input
                type="text"
                v-model="searchTerm"
                v-on:keyup.13="searchOptions"
                class="input"
                :placeholder="$t('Components.Reservation.AddReservationOptions.Placeholder_SearchOptions')" 
              /> 
            </div>
            <div class="control">
              <a
                class="button is-primary"
                :class="{ 'is-loading': isSearching }"
                @click="searchOptions"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'search']" />
                </span>
              </a>
            </div>
          </div>

          <div v-if="isSearching" class="has-text-centered">
            <ui-loader />
          </div>
        </div>
      </section>

      <article class="media" v-for="option in filteredOptions" :key="option.Id">
        <figure class="media-left">
          <p class="image is-32x32">
            <img src="https://bulma.io/images/placeholders/64x64.png" />
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <div>
              <span class="has-text-weight-bold">{{ option.OptionName }}</span>
              <span class="is-size-7">
                {{ option.PricePerItem | toCurrency }}</span
              >
              <span v-if="option.IsPP" class="is-size-7"> p.p.</span>
              <span v-if="option.PricePerHour" class="is-size-7"> p.h.</span>
            </div>

            <div class="has-text-right">
              <div class="field has-addons">
                <div class="control">
                  <input
                    type="number"
                    size="3"
                    maxlength="3"
                    :disabled="!isEmployee && option.RequiredItem === 2"
                    class="input is-small"
                    v-model="option.Amount"
                  />
                </div>
                <div class="control">
                  <a class="button is-small is-static">X</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="media-right">
          <div>
            <a @click="optionChecked(option)" class="is-size-7">
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span>{{
              $t('Components.Reservation.AddReservationOptions.Text_Add')
            }}</span>
            </a>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'
import optionProvider from '@/providers/option'

export default {
  props: {
    endMinutes: {
      default: 1440,
      type: Number,
    },

    isEmployee: {
      default: false,
      type: Boolean,
    },

    locationId: {
      default: 0,
      type: Number,
    },

    reservationId: {
      default: 0,
      type: Number,
    },

    reservationSpaces: {
      default: function() {
        return []
      },
      type: Array,
    },

    categoryId: {
      default: 0,
      type: Number,
    },

    hideSelectedItems: {
      default: false,
      type: Boolean,
    },

    startMinutes: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      isSearching: false,
      options: [],
      searchTerm: '',
      selectedOption: null,
      spaces: [],
      timeSlots: [],
    }
  },

  computed: {
    filteredOptions() {
      let options = this.options

      // if (this.hideSelectedItems) {
      //   options = options.filter(o => o.IsSelected === false)
      // }

      return options
    },
  },

  created() {
    this.fillTimeSlots()
    this.getReservationOptions()
  },

  beforeDestroy() {
    this.options = []
  },

  methods: {
    fillTimeSlots() {
      let min = this.startMinutes
      let max = this.endMinutes
      let minutes = 30

      let i = min
      while (i <= max) {
        this.timeSlots.push(i)
        i = i + minutes
      }
    },

    getReservationOptions() {
      this.isSearching = true

      optionProvider.methods
        .getReservationOptions(
          this.reservationId,
          this.categoryId,
          this.searchTerm
        )
        .then((response) => {
          if (response.status === 200) {
            this.options = response.data
          }
        })
        .finally(() => {
          this.isSearching = false
        })
    },

    searchOptions() {
      this.options = []
      this.getReservationOptions()
    },

    calculateOptionTotalPrice(value) {
      let priceTotal = value.Amount * value.PricePerItem
      if (value.MaxTotal > 0 && priceTotal > value.MaxTotal) {
        priceTotal = value.MaxTotal
      }

      return priceTotal
    },

    optionChecked(option) {
      let selectedOption = option
      selectedOption.SpaceId = 0
      selectedOption.SelectedTime = -1

      this.selectedOption = selectedOption
    },

    addOptionToReservation() {
      let self = this
      let priceTotal =
        self.selectedOption.PricePerItem * self.selectedOption.Amount

      if (
        self.selectedOption.MaxTotal > 0 &&
        priceTotal > self.selectedOption.MaxTotal
      ) {
        priceTotal = self.selectedOption.MaxTotal
      }

      self.selectedOption.PriceTotal = priceTotal
      self.selectedOption.ChangeAllInSet = false

      self.$emit('optionSelected', self.selectedOption)
      self.cancelSelectedOption()
    },

    cancelSelectedOption() {
      this.selectedOption = null
    },
  },
}
</script>
